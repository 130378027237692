// Bootstrap
@import "assets/bootstrap";

// Font awesome
@import "font-awesome";

// Iconfont
@import "assets/iconfont/font";

// Assets
@import "assets/template_core";
@import "assets/animations";
@import "assets/totop";
@import "assets/modal";

// Areas
@import "areas/top";
@import "areas/header";
@import "areas/showcase";
@import "areas/banner";
@import "areas/navigation";
@import "areas/feature";
@import "areas/maintop";
@import "areas/breadcrumbs";
@import "areas/content-top";
@import "areas/aside-left";
@import "areas/content";
@import "areas/aside-right";
@import "areas/content-bottom";
@import "areas/mainbottom";
@import "areas/bottom";
@import "areas/footer";
@import "areas/modules";
@import "areas/newsletter";
@import "areas/kontaktform";
@import "areas/brain";

// Shop
//@import "shop/shop";


/*Generelle ting SJE*/



.btn.btn-primary, .btn.btn-secondary {
    background: $secondary;
    border-radius: 20px;
    padding: 8px 15px;
    font-size: .9rem;
    border: 0;
    font-weight: 400;
}
.btn.btn-secondary { 
background: $primary;
color: $white;
  }
a, li, ol, p {
  font-size: .9rem;
}
body {
    font-size: .9rem !important;
    font-weight: 300 !important;
  }
.h2, h2 {
  text-transform: uppercase;
}
.body_forside #content {
    display: none;
}
.moduletable h3 {
    color: $secondary;
    font-size: 20px;
    text-transform: uppercase;
    font-weight: 600; 
}
ul, ol {
  padding: 0 !important;
}
.moduletable h4 {
    text-transform: uppercase;
    font-weight: 600 !important;
    margin-bottom: 20px !important;
    font-size: 2.5rem !important;
    color: $primary !important;
    padding-top: 0 !important;
}
.katitem .pull-none.item-image {
    text-align: center;
    height: 190px;
    background: #fff;
    border: 1px solid #f5f5f5;
    overflow: hidden;
}
.katitem .module_container {
    background: #f5f5f5;
}
.katitem h2 a {
    font-size: 20px;
    font-weight: 600; 
}
.katitem h2 {
    font-size: 20px;
}
.item-page.skjulheader {
  display: none;
}
a.btn.btn-secondary.active {
    background: #15212f !important;
    color: #fff !important;
}
div#navigation {
    background: #f5f5f5;
    padding-bottom: 60px;
}
html #kontaktform h4 {
    color: #fff !important;
}
.cat-children {
    display: none;
}
a.btn.btn-primary:hover {
    background: $primary !important;
}
a.btn.btn-secondary:hover {
    background: $secondary !important;
}
.langmenu {
    min-width: 140px !important;
    flex-grow: 0;
}
.langmenu ul li {
    display: inline-block;
}
.langmenu li:nth-child(1):after {
    content: "/";
}
li.lang-active a {
    color: $secondary; 
}
ul.lang-inline {
    margin-bottom: 0;
}
.topmenu {
    border-right: 1px solid $grey;
}