.temaboks .module_container {
    background: $light-grey;
    padding: 30px;
    min-height: 380px;
}
.temaboks h3, .temaboks a:hover {
	color: $primary !important;
} 
.temaboks h3 {
	font-size: 18px;
}
.temaboks a {
	color: $secondary;
}
.temaboks p {
    margin-bottom: 0;
}
.temaboks {
    margin-bottom: 30px;
} 
.item-page.starther {
    display: none;
}
.temaboks span {
    position: absolute;
    bottom: 2rem;
    padding-top: 10px;
}