#content {
	background-color: $white;
	padding: $padding-container;

	h1 {
		&:hover {
			color: map-get($colors, blue);
		}
	}
}
.share a {
    color: $secondary;
}
.share {
    text-align: right;
    margin-top: 5px;
    display: none;
}
.item-page, .item-page.catblog {
    max-width: 680px;
    margin: 0 auto;
} 
.blogcatblog {
	max-width: 100%;
}
.katitem h2 a {
    font-size: 20px;
    font-weight: 600;
    color: $primary;
}
.katitem h2 a:hover {
    color: $secondary !important;
}
span.field-value {
    color: #3c6081;
    font-size: 18px;
}
.katitem span.field-value, p.mod-articles-category-introtext {
    color: #787878;
    font-size: 16px;
}
main a {
    color: $secondary;
} 
main li, main ol {
    margin-left: 15px;
}
main li, main ol, main p {
    color: #787878;
    font-size: 18px !important;
}
.katitem .pull-none.item-image img {
	-webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
    filter: grayscale(100%);
}
.body_catblog.viden .katitem .pull-none.item-image img {
    -webkit-filter: none;
    filter: none;
    width: 100%;
    height: auto;
    object-fit: cover;
}
.katitem .pull-none.item-image {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
time {
    color: $secondary;
}
.item-page.catblog .page-header h2 {
    text-transform: none;
}
.item-page.catblog span.field-value {
    font-weight: bold;
    font-size: 18px;
    color: #212529;
}
.item-page.catblog h2 {
    text-transform: none !important;
}
.item-page.catblog .pull-none.item-image img { 
    margin-bottom: 25px!important;
}
.item-page.catblog h3 {
    font-size: 18px;
    color: $black;
    font-weight: 300;
}
main ol, main ul {
    margin-left: 5px;
    margin-bottom: 18px;
}
main h4 {
    color: $black;
}
main h5 {
    text-transform: uppercase;
}
main h4, main h5 {
    font-size: 22px;
    font-weight: 700;
    padding-top: 20px;
}
.item-page .page-header h2 {
    font-size: 22px;
    font-weight: bold;
}
.cat-introtext h3 a {
    font-size: 22px !important;
}