#top {
    background-color: #fff;
    padding: 6px 0;
    border-bottom: 1px solid $grey;


.teltop {
	ul {
		margin-bottom: 0;
		padding-left: 0;
	}
li {
	display: inline-block;
    padding-right: 20px;

    &:nth-of-type(1):before {
    content: "\f095";
    font-family: fontawesome;
    display: inline-block;
    margin-right: 8px;
    }
    &:nth-of-type(2):before {
    content: "\f0e0";
    font-family: fontawesome;
    display: inline-block;
    margin-right: 8px;
    }
}
}
}
.topmenu li {
    padding-left: 20px;
}
.topmenu ul {
    float: right;
} 
