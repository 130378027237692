.tobanner {
    float: left;
}
.tobanner.teksten {
    text-align: center;
    padding-top: 90px;
}
#showcase .container-fluid.no-gutters {
    padding: 0;
}
.showbanner .module_container {
    overflow: auto;
    background-size: cover;
}
.tobanner.teksten {
    text-align: left;
    padding: $padding-container;
    background: rgba(21,33,47,.9);
    min-height: 200px;
}
.showbanner * {
    color: #fff !important;
}
.teksten .kontainer {
    // max-width: 480px; 
    max-width: 49.1%;
    max-width: calc(51.2% - 30px);
    position: relative;
    left: -30px; 
}
.teksten .kontainer p:last-child {
    margin-bottom: 0;
    margin-top: 30px; 
}
.tobanner a.btn.btn-primary {
    margin-right: 10px; 
}
#showcase {
    background-color: $primary;

}
.blaashowcase {
    color: #fff;
    width: 100%;
    margin: 0 auto;
    max-width: 982px;
    padding: $padding-container; 
}
.blaashowcase .custom {
    column-count: 2;
    column-gap: 15px;
}
.blaashowcase header {
    text-align: center;
}
.blaashowcase h2 {
    line-height: 1;
}
.blaashowcase p {
    margin-bottom: 0; 
}
.blaashowcase.enkeltafsnit p {
    margin-bottom: 18px;
}
.blaashowcase.enkeltafsnit .custom {
    column-count: 1;
    column-gap: 0;
}
.showbanner .module_container { 
    background-position: center;
} 