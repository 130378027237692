#bottom {
    padding: $padding-container;
}
.article-introimage .module_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.article-introimage img {
    width: 230px;
    margin: 0 auto;
    height: auto;
    -webkit-filter: grayscale(100%);
    filter: grayscale(100%);
}
.viden #content .article-introimage img {
    -webkit-filter: none;
    filter: none;
    width: 100%;
    height: auto;
    object-fit: cover;
}
.viden .article-introimage .module_container {
	overflow: hidden;
}
blockquote p {
    font-size: 20px !important;
    color: #15212f;
}
blockquote::before { 
    content: "''";
    position: absolute;
    left: -14px;
    font-size: 50px;
    top: -20px;
    color: #c7b475;
}
.citaboks {
    max-width: 520px;
    margin: 0 auto;
}
.citaboks strong {
    color: $secondary;
    font-weight: bold;
    font-size: 16px;
}
.citaboks p:last-child {
    margin-bottom: 0; 
}
span.mod-articles-category-date {
    color: $secondary;
}
.cases.viden .cat-introtext h3 a {
    text-transform: none;
}
i.fa.fa-calendar-o {
    margin-right: 5px;
}
p.mod-articles-category-readmore {
    margin-bottom: 0;
} 