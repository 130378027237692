.article-introimage .module_container {
    height: 190px;
    background-size: contain !important;
    background-position: center center !important;
    background-repeat: no-repeat !important;
    border: 1px solid #f5f5f5;
}
.cat-introtext .module_container {
    background: #f5f5f5;
}
.col-sm-6.fullnew {
    float: left;
}
#maintop {
    padding: $padding-container;
}
.casetekst .module_container {
    text-align: right;
}
