#brain {
    padding: $padding-container;
    background: $primary;
} 
.billedeOppe {
    height: 120px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: 50%;
    margin-bottom: 20px;
}
.hvadhvor {
    text-align: center; 
}
.hvadhvor p {
    color: #fff; 
}
.brainbtn a.btn.btn-primary {
    margin-top: 30px;
}
.brainbtn .custom p {
    margin-bottom: 0;
} 