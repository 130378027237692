ol.breadcrumb {
    text-align: center;
    display: block;
}
.breadcrumb-item {
    display: inline;
    text-align: center;
}
i.fa.fa-location-arrow {
    display: none;
}
.breadcrumb-item+.breadcrumb-item:before {
    padding-right: .3rem;
    padding-left: .3rem;
    color: #787878;
}
li.breadcrumb-item:nth-child(2):before {
    content: "" !important;
}
.brodsti {
    margin-top: 15px;
}
.breadcrumb-item.active {
    color: #787878;
}
li.breadcrumb-item, li.breadcrumb-item a {
    font-size: 11px;
    font-weight: 400;
} 