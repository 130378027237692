/*Footer*/
#footer h3, #footer p, #footer a {
    color: #fff;
}
#footer a:hover {
	color: $secondary;
}

div#footer {
	padding: $padding-container;
	background: $primary; 
}
.footerbox li p {
    margin-bottom: 6px;
}
#footer i.fa {
    color: $secondary; 
    font-size: 20px;
    display: inline-block;
    margin-right: 10px;
}
#footer i.fa:hover {
	color: $white; 
} 
#footer li {
    display: block;
}

/*linkedin start*/

.linkedinbox i.fa.fa-linkedin {
    color: $primary !important;
    background: $secondary;
    padding: 3px 4px;
    position: relative;
    top: -5px;
}
p.lidate {
    margin-bottom: 0;
    font-weight: bold; 
}
.eachlinkedin {
    border-bottom: 1px solid $secondary;
}
.eachlinkedin:last-child {
    padding-top: 8px;
    border-bottom: 0;
}
p.linkedintext {
    margin-bottom: 8px;
}
.eachlinkedin:nth-child(3) {
    padding-top: 8px;
}
/*linkedin end*/