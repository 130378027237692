// .temaer li {
//     display: inline-block;
//     background: $white;
//     margin-bottom: 2px;
//     margin-right: 2px;
// }
.temaer li {
    display: inline-block;
    background: #fff;
    margin-bottom: 2px !important;
    margin-right: 0 !important;
}
.temaer li a {
    padding: 10px;
    display: inline-block;
    font-size: 16px; 
}

#feature {
    padding: $padding-container;
    background: $light-grey;
}
.body_starther #feature {
    background: $primary;
}
.body_blaafeature #feature {
    background: $primary;
}
.temaer a.btn.btn-primary {
    float: right !important;
}
.temaer li a:hover {
	background: $secondary;
	color: $white;
}
.billedeOppe {
    height: 120px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: 50%;
    margin-bottom: 20px;
}
.hvadhvor {
    text-align: center; 
}
.hvadhvor p {
    color: #fff; 
}
.mod-135 a.btn.btn-primary {
    margin-top: 30px;
}