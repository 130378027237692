@charset "UTF-8";
@import "map";

@mixin font-face($path, $name){
  $url: #{$path}#{$name};
  @font-face {
    font-family: $name;
    src: url('#{$url}.eot');
    src: url('#{$url}.eot?#iefix') format('eot'),
      url('#{$url}.woff2') format('woff2'),
      url('#{$url}.woff') format('woff'),
      url('#{$url}.ttf') format('truetype'),
      url('#{$url}.svg##{$name}') format('svg');
  }
}

@mixin generate-icons($prefix, $name, $glyphs){
  %icon {
    font-family: "#{$name}";
    -webkit-font-smoothing: antialiased; 
    -moz-osx-font-smoothing: grayscale;
    font-style: normal;
    font-variant: normal;
    font-weight: normal;
    // speak: none; // only necessary if not using the private unicode range (firstGlyph option)
    text-decoration: none;
    text-transform: none;
  }

  @each $glyph, $code in $glyphs{
    #{$prefix}-#{$glyph}::before {
      @extend %icon;
      content: quote(#{$code});
    }
  }
}

@include font-face('/fonts/', map-get($font, fontName));
@include generate-icons(".icon", map-get($font, fontName), map-get($font, glyphs));