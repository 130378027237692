 .newsletter h3 {
    font-size: 28px !important;
    margin-bottom: 0;
}
div#newsletter {
    background: $light-grey;
    padding: 40px 0;
 }
#newsletter input {
    border: 0;
    border-bottom: 1px solid #15212f;
    background: none;
    width: 30%;
    max-width: 100%;
    margin: 0 10px;
      font-size: 20px;
  font-weight: 300;
  font-style: italic;
}
#newsletter input::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  color: $primary;
  font-size: 20px;
  font-weight: 300;
  font-style: italic;
}
#newsletter input::-moz-placeholder { /* Firefox 19+ */
  color: $primary;
}
#newsletter input:-ms-input-placeholder { /* IE 10+ */
  color: $primary;
}
#newsletter input:-moz-placeholder { /* Firefox 18- */
  color: $primary;
}
span.PrimaryColorText {
	color: $primary;
}
.newstext h3 {
    margin-bottom: 0px;
    font-size: 28px !important;
}