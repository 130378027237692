#mainbottom {
    padding: $padding-container;
    background: $primary;
}
html .raadgivere h4, .raadgivere p {
    color: $white !important;
}
.medarbejder .module_container {
    height: 190px;
    background-size: cover;
    background-position: center center;
}
.medarbejder p {
    text-align: center;
    background: #fff;
    width: 200px;
    margin: 0 auto;
    padding: 10px 0;
    line-height: 1.2;
    position: relative; 
    bottom: -160px;
    max-width: 100%;
    display: block;
    left: 50%;
    margin-left: -100px;
}
.formResponsive .formHorizontal .formControls {
    margin-left: 0 !important;
    float: left;
    width: 100% !important;
}
.formControlLabel {
    display: none !important;
}
.rsform-block {
    float: left;
}
.formResponsive .formHorizontal .rsform-block {
    margin-bottom: 0 !important; 
}
span.formValidation {
    position: absolute;
}
form#userForm {
    margin-bottom: 0;
}
.rsform-block input, .rsform-block textarea {
    width: 100%!important;
    margin-bottom: 0!important;
    line-height: 1;
    padding: 5px 10px!important;
    border: 0 !important;
    border-bottom: 1px solid $primary !important;
    font-size: 18px !important;
    font-weight: 300;
    font-style: italic;
    color: $primary !important;
}
.rsform-block input {
    height: auto !important;
    margin-bottom: 10px !important;
}
.formResponsive input, .formResponsive textarea {
    background: none!important;
    border-radius: 0!important;
    box-shadow: none !important;
}
.rsform-block.rsform-block-message, .rsform-block input, .rsform-block textarea {
    width: 100%!important;
    margin-bottom: 0!important;
    box-sizing: border-box !important;
}
textarea#Message {
    height: 30px;
    margin-top: 30px;
}
button#Send {
    background: $secondary !important;
    width: 80px!important;
    border-radius: 20px;
    border-bottom: 0!important;
    float: right;
    font-style: normal!important;
    font-weight: 400;
    font-size: 14px!important;
    padding: 7px 8px!important;
    // margin-top: 20px;
}
.rsformkform {
    padding-top: 27px; 
}
button#Send:hover {
background: $primary !important;
}
.rsform-block ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  color: #d2d2d2;
}
.rsform-block ::-moz-placeholder { /* Firefox 19+ */
  color: #d2d2d2;
}
.rsform-block :-ms-input-placeholder { /* IE 10+ */
  color: #d2d2d2;
}
.rsform-block :-moz-placeholder { /* Firefox 18- */
  color: #d2d2d2;
}
div#kontaktform {
    background: $primary;
    padding: $padding-container;
} 
