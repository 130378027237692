.logo img {
    height: 43px;
    width: auto;
}
.main_menu li {
    font-size: 1.2em !important;
    text-transform: uppercase;
    margin-left: 25px;
    color: $primary
}
.logo p {
    margin-bottom: 25px;
    margin-top: 13px;
}
.main_menu {
    margin-top: 19px;
}
.main_menu ul.navbar-nav {
    float: right !important;
}
ul.navbar-nav {
    margin-left: auto;
}
nav.navbar.navbar-expand-lg.navbar-light {
    padding: 0;
}
li.nav-item.current.active::after, li.nav-item:hover:after {
    display: block;
    content: "";
    width: 30px;
    height: 2px;
    background: $secondary;
    margin: 0 auto;
}
li.nav-item a {
    font-size: 20px;
    font-weight: 400;
}
.topmenu li.current.active a, .topmenu li:hover a, .teltop a:hover {
    color: $secondary;
}